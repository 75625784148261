import { useState, useEffect } from "react";
import './App.scss';
import './AppDark.scss';
import { Context } from "./contextApi/context";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import RenderOnAnonymous from "./RenderOnAnonymous";
import RenderOnAuthenticated from "./RenderOnAuthenticated";
import RenderOnLogin from "./RenderOnLogin";
import axios from 'axios';
import HeaderTop from "./common/HeaderTop";
import InviteUser from "./kz-usersignup/InviteUser";
import Signup from "./kz-usersignup/Signup";
import Users from "./kz-usermanagement/Users";
import NavHeader from "./common/NavHeader";
import RegisterUser from './kz-usersignup/RegisterUser';
import CheckEmail from './kz-usersignup/CheckEmail';
import OrgLanding from "./kz-organization/OrgLanding";
import DataManage from "./kz-datamanagement/DataManage";
import Dashboard from "./kz-dashboards/Dashboard";
import ComingSoon from "./common/ComingSoon";
import MapMetadata from "./kz-datamanagement/MapMetadata";
import TeamAccess from "./kz-access-mgnt/TeamAccess";
import Task from "./kz-projects/Task";
import MyTasks from "./kz-projects/MyTasks";
import AccessMapMetadata from "./kz-access-mgnt/AccessMapMetadata";
import TemplateManagement from "./kz-datamanagement/TemplateManagement";
import Template from "./kz-datamanagement/Template";
import ManageResource from "./kz-resources/manageResource";
import CategoryDetails from "./kz-resources/tables/CategoryDetails";
import Settings from "./kz-organization/settings/Settings";
import Group from "./kz-projects/Group";
import Subgroup from "./kz-projects/Subgroup";
import Timesheet from "./kz-projects/Timesheet";
import Okr from "./kz-okr/Okr";
import MyActions from "./kz-access-mgnt/MyActions";
import CategoryAttr from "./kz-resources/tables/CategoryAttr";
import TemplateCategory from "./kz-datamanagement/TemplateCategory";
import Category from "./kz-datamanagement/Category";
import TeamMemberAccess from "./kz-access-mgnt/TeamMemberAccess";
import Graphs from "./kz-datamanagement/Graphs";
import Chat from "./kz-datamanagement/Chat";
import TemplateWizard from "./kz-datamanagement/TemplateWizard";
import AIDocs from "./kz-aidocs/AIDocs";
import MyResource from "./kz-resources/myResource";
import Policies from "./kz-projects/policies-management/Policies";
import MyPolicies from "./kz-projects/policies-management/MyPolicies";
import PolicyMyActions from "./kz-projects/policies-management/PolicyMyActions";
import Customers from "./kz-customer/customers/Customers.js";
import UserService from "./services/UserService.js";
import ManageCanvas from "./kz-dashboards/ManageCanvas.js";
import ManageLayout from "./kz-dashboards/ManageLayout.js";
import CustomerSubPlan from "./kz-customer/customerSubPlan/CustomerSubPlan.js";
import PricePlan from "./kz-customer/pricePlan/PricePlan.js";
import CustomerPlan from "./kz-customer/customerPlan/CustomerPlan.js";
import Subscription from "./kz-customer/subscription/Subscription.js";

const serverConfig = require("./config/server.js");

function Test() {
  return (<h1>Test page</h1>);
}
function App() {
  const [isAlertDropdown, setIsAlertDropdown] = useState(false);
  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  const [isOrganisationDropdown, setIsorganisationDropdown] = useState(false);
  const [collapse, setCollapse] = useState(true);
  const [isActive, setActive] = useState(true);
  const [isDarkTheme, setDarkTheme] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState('');
  const [lastSelectedOrganizationSlug, setLastSelectedOrganizationSlug] = useState('');
  const [refreshOrgList, setRefreshOrgList] = useState(false);
  const [rowId, setRowId] = useState(1)
  const [tab, setTab] = useState(1);
  // const [activeOption, setActiveOption] = useState('Users');
  const [activeOption, setActiveOption] = useState('');
  const [activeSubmenuOption, setActiveSubmenuOption] = useState('');
  const [activeTab, setActiveTab] = useState('');
  //1 is for subgroup
  //2 is for Resources
  //3 is for task
  const [taskPopup, setTaskPopup] = useState(false);

  let token = (sessionStorage.getItem('token'));

  useEffect(() => {
    if (token) {
      axios.post(`${serverConfig.api_base_url}app_user_context_get`, {
        token,
        scope: 'global',
      })
      .then(response => {
        if (response.data.status === 200) {
          let menuMinimizeValue = false;
          let isDarkThemeValue = false;
          let lastSelectedOrgSlug;
          let lastActiveTab;
          let activeMenuOption;
          let activeSubMenuOption;
  
          response.data.items.forEach(item => {
            if (item.hasOwnProperty('menu_minimize')) {
              menuMinimizeValue = item.menu_minimize === 'false';
            }
            if (item.hasOwnProperty('is_dark_theme')) {
              isDarkThemeValue = item.is_dark_theme === 'true';
            }
            if (item.hasOwnProperty('current_org')) {
              lastSelectedOrgSlug = item.current_org;
            }
            if (item.hasOwnProperty('current_app_context')) {
              lastActiveTab = item.current_app_context;
            }
            if (item.hasOwnProperty('active_menu_option')) {
              activeMenuOption = item.active_menu_option;
            }
            if (item.hasOwnProperty('active_submenu_option')) {
              activeSubMenuOption = item.active_submenu_option;
            }
          });
  
          setCollapse(menuMinimizeValue);
          setDarkTheme(isDarkThemeValue);
          setActiveTab(lastActiveTab);
          setLastSelectedOrganizationSlug(lastSelectedOrgSlug);
          setActiveOption(activeMenuOption);
          setActiveSubmenuOption(activeSubMenuOption);
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          console.error('Unexpected response status:', response.data.status);
        }
      })
      .catch(err => console.error('Error fetching user context:', err));
    }
  }, [token]);  
  
  console.log("Menu minimize", collapse);
  console.log("Theme", isDarkTheme);

  useEffect(() => {
    var colorsJson;
    const root = document.documentElement;

    axios.post(serverConfig.api_base_url + "domain_theme_css_get",
      {
        domain: window.location.host
      })
    .then(response=>{
      console.log("domain_theme_css_get response", response);
      if (response.data.status === 200) {
        if(response.data.items !== null){
          console.log(response.data.items);
          colorsJson = response.data.items;
          //primary color
          root.style.setProperty('--primary', colorsJson.primary);
          //primary color with opacity
          root.style.setProperty('--primary-opactiy-10', colorsJson.primaryOpacity10);
          root.style.setProperty('--primary-opactiy-20', colorsJson.primaryOpacity20);
          root.style.setProperty('--primary-opactiy-30', colorsJson.primaryOpacity30);
          root.style.setProperty('--primary-opactiy-40', colorsJson.primaryOpacity40);
          root.style.setProperty('--primary-opactiy-50', colorsJson.primaryOpacity50);
          root.style.setProperty('--primary-opactiy-60', colorsJson.primaryOpacity60);
          root.style.setProperty('--primary-opactiy-70', colorsJson.primaryOpacity70);
          root.style.setProperty('--primary-opactiy-80', colorsJson.primaryOpacity80);
          root.style.setProperty('--primary-opactiy-90', colorsJson.primaryOpacity90);
          //all accent colors
          root.style.setProperty('--secondary-50', colorsJson.secondary50);
          root.style.setProperty('--secondary-100', colorsJson.secondary100);
          root.style.setProperty('--secondary-200', colorsJson.secondary200);
          root.style.setProperty('--secondary-300', colorsJson.secondary300);
          root.style.setProperty('--secondary-400', colorsJson.secondary400);
          root.style.setProperty('--secondary-500', colorsJson.secondary500);
          root.style.setProperty('--secondary-600', colorsJson.secondary600);
          root.style.setProperty('--secondary-700', colorsJson.secondary700);
          root.style.setProperty('--secondary-800', colorsJson.secondary800);
          root.style.setProperty('--secondary-900', colorsJson.secondary900);
        }
      }
    })
    .catch(err=>console.log("error is",err));

    console.log(window.location.hostname, window.location.host);
    axios.post(serverConfig.api_base_url + "get_favicon_image",{domainName:window.location.host})
    .then(response=>{
      console.log('get_favicon_image response', response);
      if(response.data.status === 200) {
        var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = response.data.faviconBase64;
        document.getElementsByTagName('head')[0].appendChild(link);
      } else {
        console.log(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }, []);

  const handleActive = () => {
    setActive(!isActive)
  }
  const handleAlertDropdown = () => {
    setIsAlertDropdown(!isAlertDropdown);
    setIsProfileDropdown(false);
    setIsorganisationDropdown(false);
  }
  const handleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
    setIsAlertDropdown(false);
    setIsorganisationDropdown(false);
  }
  const handleOrganisationDropdown = () => {
    setIsAlertDropdown(false);
    setIsProfileDropdown(false);
    setIsorganisationDropdown(!isOrganisationDropdown);
  }
  const handleCollapse = () => {
    setIsAlertDropdown(false);
    setIsProfileDropdown(false);
    setIsorganisationDropdown(false);
    setCollapse(!collapse);

    axios
      .post(serverConfig.api_base_url + "app_user_context_set", {
        token: token,
        scope: "global",
        context_name: "menu_minimize",
        value: collapse,
      })
      .then((response) => {
        if (response.data.status === 200) {
          //Succes code
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          // alert(response.data.message);
        }
      })
      .catch((err) => console.log("error is", err));
  }


  const openTaskPopup = (id) => {
    setIsAlertDropdown(false);
    setIsProfileDropdown(false);
    setIsorganisationDropdown(false);
    setTaskPopup(true)
    setRowId(id)
  }
  const closeTaskPopup = () => {
    setTaskPopup(false)
  }
  const [close, setClose] = useState(true);
  return (
    <BrowserRouter>
      {/* <RenderOnAnonymous>  */}
        <Routes>
          {/* <Route path="/" element={<Signup />} />
          <Route path="/test" element={<Test />} />
          <Route path="/*" element={<Signup />} /> */}
          <Route path="/new-user" element={<Signup />} />
          <Route path="/signup" element={<RegisterUser />} />
          <Route path="/checkEmail" element={<CheckEmail />} />
          <Route path="/invite" element={<InviteUser />} />
        </Routes>
      {/* </RenderOnAnonymous>
       <RenderOnAuthenticated>  */}
        
              {/* {close &&   <Banner setClose={setClose}/>} */}
              <Routes>
                <Route path="/members" element={<RenderOnAuthenticated>
                  <div className={isDarkTheme ? 'DarkTheme' : 'lightTheme'}>
                    <Context.Provider value={{
                    lastSelectedOrganizationSlug, setLastSelectedOrganizationSlug, activeTab,
                      handleAlertDropdown,
                      activeOption, setActiveOption,
                      activeSubmenuOption, setActiveSubmenuOption,
                      handleProfileDropdown, handleOrganisationDropdown, isAlertDropdown, setIsProfileDropdown,
                      isProfileDropdown, isOrganisationDropdown, setIsorganisationDropdown, collapse, handleCollapse, isActive, setDarkTheme
                      , isDarkTheme, tab, setTab, openTaskPopup, closeTaskPopup, taskPopup, rowId, setRowId, selectedOrganization, setSelectedOrganization, refreshOrgList, setRefreshOrgList
                    }}>
                      <div className='main-wrapper'>
                        <NavHeader />
                        <HeaderTop />
                        <OrgLanding />
                      </div>
                    </Context.Provider>
                  </div>
                </RenderOnAuthenticated>} />

                <Route path="/" element={<RenderOnAuthenticated>
                  <div className={isDarkTheme ? 'DarkTheme' : 'lightTheme'}>
                    <Context.Provider value={{
                      lastSelectedOrganizationSlug, setLastSelectedOrganizationSlug, activeTab,
                      handleAlertDropdown,
                      activeOption, setActiveOption,
                      activeSubmenuOption, setActiveSubmenuOption,
                      handleProfileDropdown, handleOrganisationDropdown, isAlertDropdown, setIsProfileDropdown,
                      isProfileDropdown, isOrganisationDropdown, setIsorganisationDropdown, collapse, handleCollapse, isActive, setDarkTheme
                      , isDarkTheme, tab, setTab, openTaskPopup, closeTaskPopup, taskPopup, rowId, setRowId, selectedOrganization, setSelectedOrganization, refreshOrgList, setRefreshOrgList
                    }}>
                      <div className='main-wrapper'>
                        <NavHeader />
                        <HeaderTop />
                        {/* <Users /> */}
                      </div>
                    </Context.Provider>
                  </div>
                </RenderOnAuthenticated>} />

                <Route path="/users" element={<RenderOnAuthenticated>
                  <div className={isDarkTheme ? 'DarkTheme' : 'lightTheme'}>
                    <Context.Provider value={{
                      lastSelectedOrganizationSlug, setLastSelectedOrganizationSlug, activeTab,
                      handleAlertDropdown,
                      activeOption, setActiveOption,
                      activeSubmenuOption, setActiveSubmenuOption,
                      handleProfileDropdown, handleOrganisationDropdown, isAlertDropdown, setIsProfileDropdown,
                      isProfileDropdown, isOrganisationDropdown, setIsorganisationDropdown, collapse, handleCollapse, isActive, setDarkTheme
                      , isDarkTheme, tab, setTab, openTaskPopup, closeTaskPopup, taskPopup, rowId, setRowId, selectedOrganization, setSelectedOrganization, refreshOrgList, setRefreshOrgList
                    }}>
                      <div className='main-wrapper'>
                        <NavHeader />
                        <HeaderTop />
                        <Users />
                      </div>
                    </Context.Provider>
                  </div>
                </RenderOnAuthenticated>} />

                <Route path="/datamanagement" element={<RenderOnAuthenticated>
                  <div className={isDarkTheme ? 'DarkTheme' : 'lightTheme'}>
                    <Context.Provider value={{
                      lastSelectedOrganizationSlug, setLastSelectedOrganizationSlug, activeTab,
                      handleAlertDropdown,
                      activeOption, setActiveOption,
                      activeSubmenuOption, setActiveSubmenuOption,
                      handleProfileDropdown, handleOrganisationDropdown, isAlertDropdown, setIsProfileDropdown,
                      isProfileDropdown, isOrganisationDropdown, setIsorganisationDropdown, collapse, handleCollapse, isActive, setDarkTheme
                      , isDarkTheme, tab, setTab, openTaskPopup, closeTaskPopup, taskPopup, rowId, setRowId, selectedOrganization, setSelectedOrganization, refreshOrgList, setRefreshOrgList
                    }}>
                      <div className='main-wrapper'>
                        <NavHeader />
                        <HeaderTop />
                        <DataManage />
                      </div>
                    </Context.Provider>
                  </div>
                </RenderOnAuthenticated>} />

                <Route path="/mapmetadata" element={<RenderOnAuthenticated>
                  <div className={isDarkTheme ? 'DarkTheme' : 'lightTheme'}>
                    <Context.Provider value={{
                      lastSelectedOrganizationSlug, setLastSelectedOrganizationSlug, activeTab,
                      handleAlertDropdown,
                      activeOption, setActiveOption,
                      activeSubmenuOption, setActiveSubmenuOption,
                      handleProfileDropdown, handleOrganisationDropdown, isAlertDropdown, setIsProfileDropdown,
                      isProfileDropdown, isOrganisationDropdown, setIsorganisationDropdown, collapse, handleCollapse, isActive, setDarkTheme
                      , isDarkTheme, tab, setTab, openTaskPopup, closeTaskPopup, taskPopup, rowId, setRowId, selectedOrganization, setSelectedOrganization, refreshOrgList, setRefreshOrgList
                    }}>
                      <div className='main-wrapper'>
                        <NavHeader />
                        <HeaderTop />
                        <MapMetadata />
                      </div>
                    </Context.Provider>
                  </div>
                </RenderOnAuthenticated>} />

                <Route path="/template-management" element={<RenderOnAuthenticated>
                  <div className={isDarkTheme ? 'DarkTheme' : 'lightTheme'}>
                    <Context.Provider value={{
                      lastSelectedOrganizationSlug, setLastSelectedOrganizationSlug, activeTab,
                      handleAlertDropdown,
                      activeOption, setActiveOption,
                      activeSubmenuOption, setActiveSubmenuOption,
                      handleProfileDropdown, handleOrganisationDropdown, isAlertDropdown, setIsProfileDropdown,
                      isProfileDropdown, isOrganisationDropdown, setIsorganisationDropdown, collapse, handleCollapse, isActive, setDarkTheme
                      , isDarkTheme, tab, setTab, openTaskPopup, closeTaskPopup, taskPopup, rowId, setRowId, selectedOrganization, setSelectedOrganization, refreshOrgList, setRefreshOrgList
                    }}>
                      <div className='main-wrapper'>
                        <NavHeader />
                        <HeaderTop />
                        <TemplateManagement />
                      </div>
                    </Context.Provider>
                  </div>
                </RenderOnAuthenticated>} />

                <Route path="/template-management/template" element={<RenderOnAuthenticated>
                  <div className={isDarkTheme ? 'DarkTheme' : 'lightTheme'}>
                    <Context.Provider value={{
                      lastSelectedOrganizationSlug, setLastSelectedOrganizationSlug, activeTab,
                      handleAlertDropdown,
                      activeOption, setActiveOption,
                      activeSubmenuOption, setActiveSubmenuOption,
                      handleProfileDropdown, handleOrganisationDropdown, isAlertDropdown, setIsProfileDropdown,
                      isProfileDropdown, isOrganisationDropdown, setIsorganisationDropdown, collapse, handleCollapse, isActive, setDarkTheme
                      , isDarkTheme, tab, setTab, openTaskPopup, closeTaskPopup, taskPopup, rowId, setRowId, selectedOrganization, setSelectedOrganization, refreshOrgList, setRefreshOrgList
                    }}>
                      <div className='main-wrapper'>
                        <NavHeader />
                        <HeaderTop />
                        <Template />
                      </div>
                    </Context.Provider>
                  </div>
                </RenderOnAuthenticated>} />

                <Route path="/dashboards" element={<RenderOnAuthenticated>
                  <div className={isDarkTheme ? 'DarkTheme' : 'lightTheme'}>
                    <Context.Provider value={{
                      lastSelectedOrganizationSlug, setLastSelectedOrganizationSlug, activeTab,
                      handleAlertDropdown,
                      activeOption, setActiveOption,
                      activeSubmenuOption, setActiveSubmenuOption,
                      handleProfileDropdown, handleOrganisationDropdown, isAlertDropdown, setIsProfileDropdown,
                      isProfileDropdown, isOrganisationDropdown, setIsorganisationDropdown, collapse, handleCollapse, isActive, setDarkTheme
                      , isDarkTheme, tab, setTab, openTaskPopup, closeTaskPopup, taskPopup, rowId, setRowId, selectedOrganization, setSelectedOrganization, refreshOrgList, setRefreshOrgList
                    }}>
                      <div className='main-wrapper'>
                        <NavHeader />
                        <HeaderTop />
                        <Dashboard />
                      </div>
                    </Context.Provider>
                  </div>
                </RenderOnAuthenticated>} />

                <Route path="/teamAccess" element={<RenderOnAuthenticated>
                  <div className={isDarkTheme ? 'DarkTheme' : 'lightTheme'}>
                    <Context.Provider value={{
                    lastSelectedOrganizationSlug, setLastSelectedOrganizationSlug, activeTab,
                      handleAlertDropdown,
                      activeOption, setActiveOption,
                      activeSubmenuOption, setActiveSubmenuOption,
                      handleProfileDropdown, handleOrganisationDropdown, isAlertDropdown, setIsProfileDropdown,
                      isProfileDropdown, isOrganisationDropdown, setIsorganisationDropdown, collapse, handleCollapse, isActive, setDarkTheme
                      , isDarkTheme, tab, setTab, openTaskPopup, closeTaskPopup, taskPopup, rowId, setRowId, selectedOrganization, setSelectedOrganization, refreshOrgList, setRefreshOrgList
                    }}>
                      <div className='main-wrapper'>
                        <NavHeader />
                        <HeaderTop />
                        <TeamAccess />
                      </div>
                    </Context.Provider>
                  </div>
                </RenderOnAuthenticated>} />

                <Route path="/comingsoon" element={<RenderOnAuthenticated>
                  <div className={isDarkTheme ? 'DarkTheme' : 'lightTheme'}>
                    <Context.Provider value={{
                      lastSelectedOrganizationSlug, setLastSelectedOrganizationSlug, activeTab,
                      handleAlertDropdown,
                      activeOption, setActiveOption,
                      activeSubmenuOption, setActiveSubmenuOption,
                      handleProfileDropdown, handleOrganisationDropdown, isAlertDropdown, setIsProfileDropdown,
                      isProfileDropdown, isOrganisationDropdown, setIsorganisationDropdown, collapse, handleCollapse, isActive, setDarkTheme
                      , isDarkTheme, tab, setTab, openTaskPopup, closeTaskPopup, taskPopup, rowId, setRowId, selectedOrganization, setSelectedOrganization, refreshOrgList, setRefreshOrgList
                    }}>
                      <div className='main-wrapper'>
                        <NavHeader />
                        <HeaderTop />
                        <ComingSoon />
                      </div>
                    </Context.Provider>
                  </div>
                </RenderOnAuthenticated>} />

                <Route path="/task" element={<RenderOnAuthenticated>
                  <div className={isDarkTheme ? 'DarkTheme' : 'lightTheme'}>
                    <Context.Provider value={{
                      lastSelectedOrganizationSlug, setLastSelectedOrganizationSlug, activeTab,
                      handleAlertDropdown,
                      activeOption, setActiveOption,
                      activeSubmenuOption, setActiveSubmenuOption,
                      handleProfileDropdown, handleOrganisationDropdown, isAlertDropdown, setIsProfileDropdown,
                      isProfileDropdown, isOrganisationDropdown, setIsorganisationDropdown, collapse, handleCollapse, isActive, setDarkTheme
                      , isDarkTheme, tab, setTab, openTaskPopup, closeTaskPopup, taskPopup, rowId, setRowId, selectedOrganization, setSelectedOrganization, refreshOrgList, setRefreshOrgList
                    }}>
                      <div className='main-wrapper'>
                        <NavHeader />
                        <HeaderTop />
                        <Task />
                      </div>
                    </Context.Provider>
                  </div>
                </RenderOnAuthenticated>} />

                <Route path="/my-tasks" element={<RenderOnAuthenticated>
                  <div className={isDarkTheme ? 'DarkTheme' : 'lightTheme'}>
                    <Context.Provider value={{
                      lastSelectedOrganizationSlug, setLastSelectedOrganizationSlug, activeTab,
                      handleAlertDropdown,
                      activeOption, setActiveOption,
                      activeSubmenuOption, setActiveSubmenuOption,
                      handleProfileDropdown, handleOrganisationDropdown, isAlertDropdown, setIsProfileDropdown,
                      isProfileDropdown, isOrganisationDropdown, setIsorganisationDropdown, collapse, handleCollapse, isActive, setDarkTheme
                      , isDarkTheme, tab, setTab, openTaskPopup, closeTaskPopup, taskPopup, rowId, setRowId, selectedOrganization, setSelectedOrganization, refreshOrgList, setRefreshOrgList
                    }}>
                      <div className='main-wrapper'>
                        <NavHeader />
                        <HeaderTop />
                        <MyTasks />
                      </div>
                    </Context.Provider>
                  </div>
                </RenderOnAuthenticated>} />

                <Route path="/groups" element={<RenderOnAuthenticated>
                  <div className={isDarkTheme ? 'DarkTheme' : 'lightTheme'}>
                    <Context.Provider value={{
                      lastSelectedOrganizationSlug, setLastSelectedOrganizationSlug, activeTab,
                      handleAlertDropdown,
                      activeOption, setActiveOption,
                      activeSubmenuOption, setActiveSubmenuOption,
                      handleProfileDropdown, handleOrganisationDropdown, isAlertDropdown, setIsProfileDropdown,
                      isProfileDropdown, isOrganisationDropdown, setIsorganisationDropdown, collapse, handleCollapse, isActive, setDarkTheme
                      , isDarkTheme, tab, setTab, openTaskPopup, closeTaskPopup, taskPopup, rowId, setRowId, selectedOrganization, setSelectedOrganization, refreshOrgList, setRefreshOrgList
                    }}>
                      <div className='main-wrapper'>
                        <NavHeader />
                        <HeaderTop />
                        <Group />
                      </div>
                    </Context.Provider>
                  </div>
                </RenderOnAuthenticated>} />

                <Route path="/groups/subgroups" element={<RenderOnAuthenticated>
                  <div className={isDarkTheme ? 'DarkTheme' : 'lightTheme'}>
                    <Context.Provider value={{
                      lastSelectedOrganizationSlug, setLastSelectedOrganizationSlug, activeTab,
                      handleAlertDropdown,
                      activeOption, setActiveOption,
                      activeSubmenuOption, setActiveSubmenuOption,
                      handleProfileDropdown, handleOrganisationDropdown, isAlertDropdown, setIsProfileDropdown,
                      isProfileDropdown, isOrganisationDropdown, setIsorganisationDropdown, collapse, handleCollapse, isActive, setDarkTheme
                      , isDarkTheme, tab, setTab, openTaskPopup, closeTaskPopup, taskPopup, rowId, setRowId, selectedOrganization, setSelectedOrganization, refreshOrgList, setRefreshOrgList
                    }}>
                      <div className='main-wrapper'>
                        <NavHeader />
                        <HeaderTop />
                        <Subgroup />
                      </div>
                    </Context.Provider>
                  </div>
                </RenderOnAuthenticated>} /> 

                <Route path="/timesheet" element={<RenderOnAuthenticated>
                  <div className={isDarkTheme ? 'DarkTheme' : 'lightTheme'}>
                    <Context.Provider value={{
                      lastSelectedOrganizationSlug, setLastSelectedOrganizationSlug, activeTab,
                      handleAlertDropdown,
                      activeOption, setActiveOption,
                      activeSubmenuOption, setActiveSubmenuOption,
                      handleProfileDropdown, handleOrganisationDropdown, isAlertDropdown, setIsProfileDropdown,
                      isProfileDropdown, isOrganisationDropdown, setIsorganisationDropdown, collapse, handleCollapse, isActive, setDarkTheme
                      , isDarkTheme, tab, setTab, openTaskPopup, closeTaskPopup, taskPopup, rowId, setRowId, selectedOrganization, setSelectedOrganization, refreshOrgList, setRefreshOrgList
                    }}>
                      <div className='main-wrapper'>
                        <NavHeader />
                        <HeaderTop />
                        <Timesheet />
                      </div>
                    </Context.Provider>
                  </div>
                </RenderOnAuthenticated>} /> 

                <Route path="/accessmanage" element={<RenderOnAuthenticated>
                  <div className={isDarkTheme ? 'DarkTheme' : 'lightTheme'}>
                    <Context.Provider value={{
                    lastSelectedOrganizationSlug, setLastSelectedOrganizationSlug, activeTab,
                      handleAlertDropdown,
                      activeOption, setActiveOption,
                      activeSubmenuOption, setActiveSubmenuOption,
                      handleProfileDropdown, handleOrganisationDropdown, isAlertDropdown, setIsProfileDropdown,
                      isProfileDropdown, isOrganisationDropdown, setIsorganisationDropdown, collapse, handleCollapse, isActive, setDarkTheme
                      , isDarkTheme, tab, setTab, openTaskPopup, closeTaskPopup, taskPopup, rowId, setRowId, selectedOrganization, setSelectedOrganization, refreshOrgList, setRefreshOrgList
                    }}>
                      <div className='main-wrapper'>
                        <NavHeader />
                        <HeaderTop />
                        <AccessMapMetadata />
                      </div>
                    </Context.Provider>
                  </div>
                </RenderOnAuthenticated>} />

                <Route path="/settings" element={<RenderOnAuthenticated>
                  <div className={isDarkTheme ? 'DarkTheme' : 'lightTheme'}>
                    <Context.Provider value={{
                    lastSelectedOrganizationSlug, setLastSelectedOrganizationSlug, activeTab,
                      handleAlertDropdown,
                      activeOption, setActiveOption,
                      activeSubmenuOption, setActiveSubmenuOption,
                      handleProfileDropdown, handleOrganisationDropdown, isAlertDropdown, setIsProfileDropdown,
                      isProfileDropdown, isOrganisationDropdown, setIsorganisationDropdown, collapse, handleCollapse, isActive, setDarkTheme
                      , isDarkTheme, tab, setTab, openTaskPopup, closeTaskPopup, taskPopup, rowId, setRowId, selectedOrganization, setSelectedOrganization, refreshOrgList, setRefreshOrgList
                    }}>
                      <div className='main-wrapper'>
                        <NavHeader />
                        <HeaderTop />
                        <Settings />
                      </div>
                    </Context.Provider>
                  </div>
                </RenderOnAuthenticated>} />

                <Route path="/okr" element={<RenderOnAuthenticated>
                  <div className={isDarkTheme ? 'DarkTheme' : 'lightTheme'}>
                    <Context.Provider value={{
                      lastSelectedOrganizationSlug, setLastSelectedOrganizationSlug, activeTab,
                      handleAlertDropdown,
                      activeOption, setActiveOption,
                      activeSubmenuOption, setActiveSubmenuOption,
                      handleProfileDropdown, handleOrganisationDropdown, isAlertDropdown, setIsProfileDropdown,
                      isProfileDropdown, isOrganisationDropdown, setIsorganisationDropdown, collapse, handleCollapse, isActive, setDarkTheme
                      , isDarkTheme, tab, setTab, openTaskPopup, closeTaskPopup, taskPopup, rowId, setRowId, selectedOrganization, setSelectedOrganization, refreshOrgList, setRefreshOrgList
                    }}>
                      <div className='main-wrapper'>
                        <NavHeader />
                        <HeaderTop />
                        <Okr />
                      </div>
                    </Context.Provider>
                  </div>
                </RenderOnAuthenticated>} />

                <Route path="/myActions" element={<RenderOnAuthenticated>
                  <div className={isDarkTheme ? 'DarkTheme' : 'lightTheme'}>
                    <Context.Provider value={{
                    lastSelectedOrganizationSlug, setLastSelectedOrganizationSlug, activeTab,
                      handleAlertDropdown,
                      activeOption, setActiveOption,
                      activeSubmenuOption, setActiveSubmenuOption,
                      handleProfileDropdown, handleOrganisationDropdown, isAlertDropdown, setIsProfileDropdown,
                      isProfileDropdown, isOrganisationDropdown, setIsorganisationDropdown, collapse, handleCollapse, isActive, setDarkTheme
                      , isDarkTheme, tab, setTab, openTaskPopup, closeTaskPopup, taskPopup, rowId, setRowId, selectedOrganization, setSelectedOrganization, refreshOrgList, setRefreshOrgList
                    }}>
                      <div className='main-wrapper'>
                        <NavHeader />
                        <HeaderTop />
                        <MyActions />
                      </div>
                    </Context.Provider>
                  </div>
                </RenderOnAuthenticated>} />

                <Route path="/manage-resource" element={<RenderOnAuthenticated>
                  <div className={isDarkTheme ? 'DarkTheme' : 'lightTheme'}>
                    <Context.Provider value={{
                    lastSelectedOrganizationSlug, setLastSelectedOrganizationSlug, activeTab,
                      handleAlertDropdown,
                      activeOption, setActiveOption,
                      activeSubmenuOption, setActiveSubmenuOption,
                      handleProfileDropdown, handleOrganisationDropdown, isAlertDropdown, setIsProfileDropdown,
                      isProfileDropdown, isOrganisationDropdown, setIsorganisationDropdown, collapse, handleCollapse, isActive, setDarkTheme
                      , isDarkTheme, tab, setTab, openTaskPopup, closeTaskPopup, taskPopup, rowId, setRowId, selectedOrganization, setSelectedOrganization, refreshOrgList, setRefreshOrgList
                    }}>
                      <div className='main-wrapper'>
                        <NavHeader />
                        <HeaderTop />
                        <ManageResource />
                      </div>
                    </Context.Provider>
                  </div>
                </RenderOnAuthenticated>} />

                <Route path="/my-resource" element={<RenderOnAuthenticated>
                  <div className={isDarkTheme ? 'DarkTheme' : 'lightTheme'}>
                    <Context.Provider value={{
                    lastSelectedOrganizationSlug, setLastSelectedOrganizationSlug, activeTab,
                      handleAlertDropdown,
                      activeOption, setActiveOption,
                      activeSubmenuOption, setActiveSubmenuOption,
                      handleProfileDropdown, handleOrganisationDropdown, isAlertDropdown, setIsProfileDropdown,
                      isProfileDropdown, isOrganisationDropdown, setIsorganisationDropdown, collapse, handleCollapse, isActive, setDarkTheme
                      , isDarkTheme, tab, setTab, openTaskPopup, closeTaskPopup, taskPopup, rowId, setRowId, selectedOrganization, setSelectedOrganization, refreshOrgList, setRefreshOrgList
                    }}>
                      <div className='main-wrapper'>
                        <NavHeader />
                        <HeaderTop />
                        <MyResource />
                      </div>
                    </Context.Provider>
                  </div>
                </RenderOnAuthenticated>} />

                <Route path="/Category-details" element={<RenderOnAuthenticated>
                  <div className={isDarkTheme ? 'DarkTheme' : 'lightTheme'}>
                    <Context.Provider value={{
                    lastSelectedOrganizationSlug, setLastSelectedOrganizationSlug, activeTab,
                      handleAlertDropdown,
                      activeOption, setActiveOption,
                      activeSubmenuOption, setActiveSubmenuOption,
                      handleProfileDropdown, handleOrganisationDropdown, isAlertDropdown, setIsProfileDropdown,
                      isProfileDropdown, isOrganisationDropdown, setIsorganisationDropdown, collapse, handleCollapse, isActive, setDarkTheme
                      , isDarkTheme, tab, setTab, openTaskPopup, closeTaskPopup, taskPopup, rowId, setRowId, selectedOrganization, setSelectedOrganization, refreshOrgList, setRefreshOrgList
                    }}>
                      <div className='main-wrapper'>
                        <NavHeader />
                        <HeaderTop />
                        <CategoryDetails />
                      </div>
                    </Context.Provider>
                  </div>
                </RenderOnAuthenticated>} />

                <Route path="/Category-details/CatAttr" element={<RenderOnAuthenticated>
                  <div className={isDarkTheme ? 'DarkTheme' : 'lightTheme'}>
                    <Context.Provider value={{
                    lastSelectedOrganizationSlug, setLastSelectedOrganizationSlug, activeTab,
                      handleAlertDropdown,
                      activeOption, setActiveOption,
                      activeSubmenuOption, setActiveSubmenuOption,
                      handleProfileDropdown, handleOrganisationDropdown, isAlertDropdown, setIsProfileDropdown,
                      isProfileDropdown, isOrganisationDropdown, setIsorganisationDropdown, collapse, handleCollapse, isActive, setDarkTheme
                      , isDarkTheme, tab, setTab, openTaskPopup, closeTaskPopup, taskPopup, rowId, setRowId, selectedOrganization, setSelectedOrganization, refreshOrgList, setRefreshOrgList
                    }}>
                      <div className='main-wrapper'>
                        <NavHeader />
                        <HeaderTop />
                        <CategoryAttr />
                      </div>
                    </Context.Provider>
                  </div>
                </RenderOnAuthenticated>} />

                <Route path="/template-category" element={<RenderOnAuthenticated>
                  <div className={isDarkTheme ? 'DarkTheme' : 'lightTheme'}>
                    <Context.Provider value={{
                      lastSelectedOrganizationSlug, setLastSelectedOrganizationSlug, activeTab,
                      handleAlertDropdown,
                      activeOption, setActiveOption,
                      activeSubmenuOption, setActiveSubmenuOption,
                      handleProfileDropdown, handleOrganisationDropdown, isAlertDropdown, setIsProfileDropdown,
                      isProfileDropdown, isOrganisationDropdown, setIsorganisationDropdown, collapse, handleCollapse, isActive, setDarkTheme
                      , isDarkTheme, tab, setTab, openTaskPopup, closeTaskPopup, taskPopup, rowId, setRowId, selectedOrganization, setSelectedOrganization, refreshOrgList, setRefreshOrgList
                    }}>
                      <div className='main-wrapper'>
                        <NavHeader />
                        <HeaderTop />
                        <TemplateCategory />
                      </div>
                    </Context.Provider>
                  </div>
                </RenderOnAuthenticated>} />

                <Route path="/template-category/category" element={<RenderOnAuthenticated>
                  <div className={isDarkTheme ? 'DarkTheme' : 'lightTheme'}>
                    <Context.Provider value={{
                      lastSelectedOrganizationSlug, setLastSelectedOrganizationSlug, activeTab,
                      handleAlertDropdown,
                      activeOption, setActiveOption,
                      activeSubmenuOption, setActiveSubmenuOption,
                      handleProfileDropdown, handleOrganisationDropdown, isAlertDropdown, setIsProfileDropdown,
                      isProfileDropdown, isOrganisationDropdown, setIsorganisationDropdown, collapse, handleCollapse, isActive, setDarkTheme
                      , isDarkTheme, tab, setTab, openTaskPopup, closeTaskPopup, taskPopup, rowId, setRowId, selectedOrganization, setSelectedOrganization, refreshOrgList, setRefreshOrgList
                    }}>
                      <div className='main-wrapper'>
                        <NavHeader />
                        <HeaderTop />
                        <Category />
                      </div>
                    </Context.Provider>
                  </div>
                </RenderOnAuthenticated>} />

                <Route path="/reporteeAccess" element={<RenderOnAuthenticated>
                  <div className={isDarkTheme ? 'DarkTheme' : 'lightTheme'}>
                    <Context.Provider value={{
                    lastSelectedOrganizationSlug, setLastSelectedOrganizationSlug, activeTab,
                      handleAlertDropdown,
                      activeOption, setActiveOption,
                      activeSubmenuOption, setActiveSubmenuOption,
                      handleProfileDropdown, handleOrganisationDropdown, isAlertDropdown, setIsProfileDropdown,
                      isProfileDropdown, isOrganisationDropdown, setIsorganisationDropdown, collapse, handleCollapse, isActive, setDarkTheme
                      , isDarkTheme, tab, setTab, openTaskPopup, closeTaskPopup, taskPopup, rowId, setRowId, selectedOrganization, setSelectedOrganization, refreshOrgList, setRefreshOrgList
                    }}>
                      <div className='main-wrapper'>
                        <NavHeader />
                        <HeaderTop />
                        <TeamMemberAccess />
                      </div>
                    </Context.Provider>
                  </div>
                </RenderOnAuthenticated>} />

                <Route path="/template-wizard" element={<RenderOnAuthenticated>
                  <div className={isDarkTheme ? 'DarkTheme' : 'lightTheme'}>
                    <Context.Provider value={{
                      lastSelectedOrganizationSlug, setLastSelectedOrganizationSlug, activeTab,
                      handleAlertDropdown,
                      activeOption, setActiveOption,
                      activeSubmenuOption, setActiveSubmenuOption,
                      handleProfileDropdown, handleOrganisationDropdown, isAlertDropdown, setIsProfileDropdown,
                      isProfileDropdown, isOrganisationDropdown, setIsorganisationDropdown, collapse, handleCollapse, isActive, setDarkTheme
                      , isDarkTheme, tab, setTab, openTaskPopup, closeTaskPopup, taskPopup, rowId, setRowId, selectedOrganization, setSelectedOrganization, refreshOrgList, setRefreshOrgList
                    }}>
                      <div className='main-wrapper'>
                        <NavHeader />
                        <HeaderTop />
                        <TemplateWizard/>
                      </div>
                    </Context.Provider>
                  </div>
                </RenderOnAuthenticated>} />

                <Route path="/graphs" element={<RenderOnAuthenticated>
                  <div className={isDarkTheme ? 'DarkTheme' : 'lightTheme'}>
                    <Context.Provider value={{
                      lastSelectedOrganizationSlug, setLastSelectedOrganizationSlug, activeTab,
                      handleAlertDropdown,
                      activeOption, setActiveOption,
                      activeSubmenuOption, setActiveSubmenuOption,
                      handleProfileDropdown, handleOrganisationDropdown, isAlertDropdown, setIsProfileDropdown,
                      isProfileDropdown, isOrganisationDropdown, setIsorganisationDropdown, collapse, handleCollapse, isActive, setDarkTheme
                      , isDarkTheme, tab, setTab, openTaskPopup, closeTaskPopup, taskPopup, rowId, setRowId, selectedOrganization, setSelectedOrganization, refreshOrgList, setRefreshOrgList
                    }}>
                      <div className='main-wrapper'>
                        <NavHeader />
                        <HeaderTop />
                        <Graphs/>
                      </div>
                    </Context.Provider>
                  </div>
                </RenderOnAuthenticated>} />

                <Route path="/chat" element={<RenderOnAuthenticated>
                  <div className={isDarkTheme ? 'DarkTheme' : 'lightTheme'}>
                    <Context.Provider value={{
                      lastSelectedOrganizationSlug, setLastSelectedOrganizationSlug, activeTab,
                      handleAlertDropdown,
                      activeOption, setActiveOption,
                      activeSubmenuOption, setActiveSubmenuOption,
                      handleProfileDropdown, handleOrganisationDropdown, isAlertDropdown, setIsProfileDropdown,
                      isProfileDropdown, isOrganisationDropdown, setIsorganisationDropdown, collapse, handleCollapse, isActive, setDarkTheme
                      , isDarkTheme, tab, setTab, openTaskPopup, closeTaskPopup, taskPopup, rowId, setRowId, selectedOrganization, setSelectedOrganization, refreshOrgList, setRefreshOrgList
                    }}>
                      <div className='main-wrapper'>
                        <NavHeader />
                        <HeaderTop />
                        <Chat/>
                      </div>
                    </Context.Provider>
                  </div>
                </RenderOnAuthenticated>} />

                <Route path="/aidocs" element={<RenderOnAuthenticated>
                  <div className={isDarkTheme ? 'DarkTheme' : 'lightTheme'}>
                    <Context.Provider value={{
                    lastSelectedOrganizationSlug, setLastSelectedOrganizationSlug, activeTab,
                      handleAlertDropdown,
                      activeOption, setActiveOption,
                      activeSubmenuOption, setActiveSubmenuOption,
                      handleProfileDropdown, handleOrganisationDropdown, isAlertDropdown, setIsProfileDropdown,
                      isProfileDropdown, isOrganisationDropdown, setIsorganisationDropdown, collapse, handleCollapse, isActive, setDarkTheme
                      , isDarkTheme, tab, setTab, openTaskPopup, closeTaskPopup, taskPopup, rowId, setRowId, selectedOrganization, setSelectedOrganization, refreshOrgList, setRefreshOrgList
                    }}>
                      <div className='main-wrapper'>
                        <NavHeader />
                        <HeaderTop />
                        <AIDocs />
                      </div>
                    </Context.Provider>
                  </div>
                </RenderOnAuthenticated>} />

                <Route path="/policies" element={<RenderOnAuthenticated>
                  <div className={isDarkTheme ? 'DarkTheme' : 'lightTheme'}>
                    <Context.Provider value={{
                      lastSelectedOrganizationSlug, setLastSelectedOrganizationSlug, activeTab,
                      handleAlertDropdown,
                      activeOption, setActiveOption,
                      activeSubmenuOption, setActiveSubmenuOption,
                      handleProfileDropdown, handleOrganisationDropdown, isAlertDropdown, setIsProfileDropdown,
                      isProfileDropdown, isOrganisationDropdown, setIsorganisationDropdown, collapse, handleCollapse, isActive, setDarkTheme
                      , isDarkTheme, tab, setTab, openTaskPopup, closeTaskPopup, taskPopup, rowId, setRowId, selectedOrganization, setSelectedOrganization, refreshOrgList, setRefreshOrgList
                    }}>
                      <div className='main-wrapper'>
                        <NavHeader />
                        <HeaderTop />
                        <Policies />
                      </div>
                    </Context.Provider>
                  </div>
                </RenderOnAuthenticated>} /> 

                <Route path="/my-policies" element={<RenderOnAuthenticated>
                  <div className={isDarkTheme ? 'DarkTheme' : 'lightTheme'}>
                    <Context.Provider value={{
                      lastSelectedOrganizationSlug, setLastSelectedOrganizationSlug, activeTab,
                      handleAlertDropdown,
                      activeOption, setActiveOption,
                      activeSubmenuOption, setActiveSubmenuOption,
                      handleProfileDropdown, handleOrganisationDropdown, isAlertDropdown, setIsProfileDropdown,
                      isProfileDropdown, isOrganisationDropdown, setIsorganisationDropdown, collapse, handleCollapse, isActive, setDarkTheme
                      , isDarkTheme, tab, setTab, openTaskPopup, closeTaskPopup, taskPopup, rowId, setRowId, selectedOrganization, setSelectedOrganization, refreshOrgList, setRefreshOrgList
                    }}>
                      <div className='main-wrapper'>
                        <NavHeader />
                        <HeaderTop />
                        <MyPolicies />
                      </div>
                    </Context.Provider>
                  </div>
                </RenderOnAuthenticated>} /> 

                <Route path="/my-actions" element={<RenderOnAuthenticated>
                  <div className={isDarkTheme ? 'DarkTheme' : 'lightTheme'}>
                    <Context.Provider value={{
                      lastSelectedOrganizationSlug, setLastSelectedOrganizationSlug, activeTab,
                      handleAlertDropdown,
                      activeOption, setActiveOption,
                      activeSubmenuOption, setActiveSubmenuOption,
                      handleProfileDropdown, handleOrganisationDropdown, isAlertDropdown, setIsProfileDropdown,
                      isProfileDropdown, isOrganisationDropdown, setIsorganisationDropdown, collapse, handleCollapse, isActive, setDarkTheme
                      , isDarkTheme, tab, setTab, openTaskPopup, closeTaskPopup, taskPopup, rowId, setRowId, selectedOrganization, setSelectedOrganization, refreshOrgList, setRefreshOrgList
                    }}>
                      <div className='main-wrapper'>
                        <NavHeader />
                        <HeaderTop />
                        <PolicyMyActions />
                      </div>
                    </Context.Provider>
                  </div>
                </RenderOnAuthenticated>} /> 

                <Route path="/customers" element={<RenderOnAuthenticated>
                  <div className={isDarkTheme ? 'DarkTheme' : 'lightTheme'}>
                    <Context.Provider value={{
                    lastSelectedOrganizationSlug, setLastSelectedOrganizationSlug, activeTab,
                      handleAlertDropdown,
                      activeOption, setActiveOption,
                      activeSubmenuOption, setActiveSubmenuOption,
                      handleProfileDropdown, handleOrganisationDropdown, isAlertDropdown, setIsProfileDropdown,
                      isProfileDropdown, isOrganisationDropdown, setIsorganisationDropdown, collapse, handleCollapse, isActive, setDarkTheme
                      , isDarkTheme, tab, setTab, openTaskPopup, closeTaskPopup, taskPopup, rowId, setRowId, selectedOrganization, setSelectedOrganization, refreshOrgList, setRefreshOrgList
                    }}>
                      <div className='main-wrapper'>
                        <NavHeader />
                        <HeaderTop />
                        <Customers />
                      </div>
                    </Context.Provider>
                  </div>
                </RenderOnAuthenticated>} /> 

                <Route path="/dashboard_manage" element={<RenderOnAuthenticated>
                  <div className={isDarkTheme ? 'DarkTheme' : 'lightTheme'}>
                    <Context.Provider value={{
                      lastSelectedOrganizationSlug, setLastSelectedOrganizationSlug, activeTab,
                      handleAlertDropdown,
                      activeOption, setActiveOption,
                      activeSubmenuOption, setActiveSubmenuOption,
                      handleProfileDropdown, handleOrganisationDropdown, isAlertDropdown, setIsProfileDropdown,
                      isProfileDropdown, isOrganisationDropdown, setIsorganisationDropdown, collapse, handleCollapse, isActive, setDarkTheme
                      , isDarkTheme, tab, setTab, openTaskPopup, closeTaskPopup, taskPopup, rowId, setRowId, selectedOrganization, setSelectedOrganization, refreshOrgList, setRefreshOrgList
                    }}>
                      <div className='main-wrapper'>
                        <NavHeader />
                        <HeaderTop />
                        <ManageCanvas/>
                      </div>
                    </Context.Provider>
                  </div>
                </RenderOnAuthenticated>} /> 

                <Route path="/dashboard_manage_layout" element={<RenderOnAuthenticated>
                  <div className={isDarkTheme ? 'DarkTheme' : 'lightTheme'}>
                    <Context.Provider value={{
                      lastSelectedOrganizationSlug, setLastSelectedOrganizationSlug, activeTab,
                      handleAlertDropdown,
                      activeOption, setActiveOption,
                      activeSubmenuOption, setActiveSubmenuOption,
                      handleProfileDropdown, handleOrganisationDropdown, isAlertDropdown, setIsProfileDropdown,
                      isProfileDropdown, isOrganisationDropdown, setIsorganisationDropdown, collapse, handleCollapse, isActive, setDarkTheme
                      , isDarkTheme, tab, setTab, openTaskPopup, closeTaskPopup, taskPopup, rowId, setRowId, selectedOrganization, setSelectedOrganization, refreshOrgList, setRefreshOrgList
                    }}>
                      <div className='main-wrapper'>
                        <NavHeader />
                        <HeaderTop />
                        <ManageLayout/>
                      </div>
                    </Context.Provider>
                  </div>
                </RenderOnAuthenticated>} /> 

                <Route path="/subscription-plan" element={<RenderOnAuthenticated>
                  <div className={isDarkTheme ? 'DarkTheme' : 'lightTheme'}>
                    <Context.Provider value={{
                      lastSelectedOrganizationSlug, setLastSelectedOrganizationSlug, activeTab,
                      handleAlertDropdown,
                      activeOption, setActiveOption,
                      activeSubmenuOption, setActiveSubmenuOption,
                      handleProfileDropdown, handleOrganisationDropdown, isAlertDropdown, setIsProfileDropdown,
                      isProfileDropdown, isOrganisationDropdown, setIsorganisationDropdown, collapse, handleCollapse, isActive, setDarkTheme
                      , isDarkTheme, tab, setTab, openTaskPopup, closeTaskPopup, taskPopup, rowId, setRowId, selectedOrganization, setSelectedOrganization, refreshOrgList, setRefreshOrgList
                    }}>
                      <div className='main-wrapper'>
                        <NavHeader />
                        <HeaderTop />
                        <CustomerSubPlan/>
                      </div>
                    </Context.Provider>
                  </div>
                </RenderOnAuthenticated>} />
                <Route path="/price-plan" element={<RenderOnAuthenticated>
                  <div className={isDarkTheme ? 'DarkTheme' : 'lightTheme'}>
                    <Context.Provider value={{
                      lastSelectedOrganizationSlug, setLastSelectedOrganizationSlug, activeTab,
                      handleAlertDropdown,
                      activeOption, setActiveOption,
                      activeSubmenuOption, setActiveSubmenuOption,
                      handleProfileDropdown, handleOrganisationDropdown, isAlertDropdown, setIsProfileDropdown,
                      isProfileDropdown, isOrganisationDropdown, setIsorganisationDropdown, collapse, handleCollapse, isActive, setDarkTheme
                      , isDarkTheme, tab, setTab, openTaskPopup, closeTaskPopup, taskPopup, rowId, setRowId, selectedOrganization, setSelectedOrganization, refreshOrgList, setRefreshOrgList
                    }}>
                      <div className='main-wrapper'>
                        <NavHeader />
                        <HeaderTop />
                        <PricePlan/>
                      </div>
                    </Context.Provider>
                  </div>
                </RenderOnAuthenticated>} />

                <Route path="/customer-plans" element={<RenderOnAuthenticated>
                  <div className={isDarkTheme ? 'DarkTheme' : 'lightTheme'}>
                    <Context.Provider value={{
                      lastSelectedOrganizationSlug, setLastSelectedOrganizationSlug, activeTab,
                      handleAlertDropdown,
                      activeOption, setActiveOption,
                      activeSubmenuOption, setActiveSubmenuOption,
                      handleProfileDropdown, handleOrganisationDropdown, isAlertDropdown, setIsProfileDropdown,
                      isProfileDropdown, isOrganisationDropdown, setIsorganisationDropdown, collapse, handleCollapse, isActive, setDarkTheme
                      , isDarkTheme, tab, setTab, openTaskPopup, closeTaskPopup, taskPopup, rowId, setRowId, selectedOrganization, setSelectedOrganization, refreshOrgList, setRefreshOrgList
                    }}>
                      <div className='main-wrapper'>
                        <NavHeader />
                        <HeaderTop />
                        <CustomerPlan/>
                      </div>
                    </Context.Provider>
                  </div>
                </RenderOnAuthenticated>} /> 

                <Route path="/subscription" element={<RenderOnAuthenticated>
                  <div className={isDarkTheme ? 'DarkTheme' : 'lightTheme'}>
                    <Context.Provider value={{
                      lastSelectedOrganizationSlug, setLastSelectedOrganizationSlug, activeTab,
                      handleAlertDropdown,
                      activeOption, setActiveOption,
                      activeSubmenuOption, setActiveSubmenuOption,
                      handleProfileDropdown, handleOrganisationDropdown, isAlertDropdown, setIsProfileDropdown,
                      isProfileDropdown, isOrganisationDropdown, setIsorganisationDropdown, collapse, handleCollapse, isActive, setDarkTheme
                      , isDarkTheme, tab, setTab, openTaskPopup, closeTaskPopup, taskPopup, rowId, setRowId, selectedOrganization, setSelectedOrganization, refreshOrgList, setRefreshOrgList
                    }}>
                      <div className='main-wrapper'>
                        <NavHeader />
                        <HeaderTop />
                        <Subscription/>
                      </div>
                    </Context.Provider>
                  </div>
                </RenderOnAuthenticated>} />

              </Routes>
            {/* </div>
          </Context.Provider>
        </div> */}
      {/* </RenderOnAuthenticated>  */}
    </BrowserRouter>
  );
}
export default App;